import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/firebase'; // Import Firebase initialization
import { shouldShowApp, getRedirectUrl } from './utils/environment';

// Check if we should show the app or redirect
if (shouldShowApp()) {
  // We're in local development or on the demo domain - render the app normally
  const rootElement = document.getElementById('root');
  
  // Make sure the root element exists before creating the React root
  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } else {
    console.error('Root element not found - unable to mount React app');
  }
} else {
  // We're on the main domain - redirect to welcome page
  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    window.location.href = redirectUrl;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
