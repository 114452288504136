import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled component for highlighting a section
const HighlightContainer = styled.div`
  position: relative;
  
  &.highlight-active {
    box-shadow: 0 0 15px rgba(16, 185, 129, 0.7);
    border-radius: 8px;
    animation: pulse 2s infinite;
    
    @keyframes pulse {
      0% {
        box-shadow: 0 0 5px rgba(16, 185, 129, 0.7);
      }
      50% {
        box-shadow: 0 0 20px rgba(16, 185, 129, 0.9);
      }
      100% {
        box-shadow: 0 0 5px rgba(16, 185, 129, 0.7);
      }
    }
  }
`;

// Badge that appears in the corner
const FeatureBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #10b981;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  z-index: 10;
  
  &:hover::after {
    content: "${props => props.tooltip || 'New feature'}";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #333;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
    width: 250px;
    font-size: 0.75rem;
    margin-top: 5px;
    z-index: 1000;
    white-space: normal;
  }
`;

/**
 * FeatureHighlight - Highlights a new feature with a subtle glow and badge
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - Content to highlight
 * @param {string} props.featureId - Unique ID for this feature (used for localStorage)
 * @param {string} props.badgeText - Text to display in the badge
 * @param {string} props.tooltip - Tooltip text on hover
 * @param {number} props.duration - How long to show the highlight (in milliseconds)
 * @param {boolean} props.showBadge - Whether to show the badge
 */
const FeatureHighlight = ({
  children,
  featureId,
  badgeText = "New",
  tooltip = "New feature added to help you analyze ecosystem dependencies",
  duration = 5000,
  showBadge = true
}) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  
  useEffect(() => {
    // Check if this feature has been seen before
    const hasSeenFeature = localStorage.getItem(`seen_feature_${featureId}`);
    
    if (!hasSeenFeature) {
      // Highlight the feature
      setIsHighlighted(true);
      
      // Automatically remove the highlight after the duration
      const timer = setTimeout(() => {
        setIsHighlighted(false);
        localStorage.setItem(`seen_feature_${featureId}`, 'true');
      }, duration);
      
      return () => clearTimeout(timer);
    }
  }, [featureId, duration]);
  
  return (
    <HighlightContainer className={isHighlighted ? 'highlight-active' : ''}>
      {isHighlighted && showBadge && (
        <FeatureBadge tooltip={tooltip}>
          {badgeText}
        </FeatureBadge>
      )}
      {children}
    </HighlightContainer>
  );
};

export default FeatureHighlight; 