import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import FeatureHighlight from '../components/FeatureHighlight';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { 
  FiArrowLeft,
  FiPlay,
  FiDownload,
  FiSave,
  FiSliders,
  FiChevronRight,
  FiPlusCircle,
  FiAlertTriangle,
  FiDollarSign,
  FiCalendar,
  FiArrowUp,
  FiArrowDown,
  FiCopy,
  FiBarChart2,
  FiMap,
  FiTrendingUp,
  FiTrendingDown,
  FiRefreshCw
} from 'react-icons/fi';
import { assets } from '../data/mockData';

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const PageContainer = styled.div`
  padding: 1rem 0;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

const PageDescription = styled.p`
  color: var(--text-light);
  margin: 0.5rem 0 0;
  max-width: 800px;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  background-color: ${props => props.primary ? 'var(--primary)' : 'white'};
  color: ${props => props.primary ? 'white' : 'var(--text)'};
  border: 1px solid ${props => props.primary ? 'var(--primary)' : 'var(--border)'};
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.primary ? 'var(--primary-dark)' : 'var(--background)'};
    border-color: ${props => props.primary ? 'var(--primary-dark)' : 'var(--primary)'};
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1.5rem;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const SimulationSidebar = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const SidebarHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SimulationList = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const SimulationItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: var(--background);
  }
  
  &.active {
    background-color: rgba(59, 130, 246, 0.05);
    border-left: 3px solid var(--primary);
  }
  
  .name {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  .meta {
    font-size: 0.85rem;
    color: var(--text-light);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-top: 0.5rem;
  }
  
  .tag {
    font-size: 0.7rem;
    padding: 0.15rem 0.5rem;
    border-radius: 1rem;
    background-color: var(--background);
    color: var(--text-light);
  }
`;

const SimulationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const SimulationHeader = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
  }
  
  .description {
    font-size: 0.95rem;
    color: var(--text-light);
    margin-bottom: 1rem;
  }
  
  .meta-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 1rem;
  }
  
  .meta-item {
    display: flex;
    flex-direction: column;
    
    .label {
      font-size: 0.85rem;
      color: var(--text-light);
      margin-bottom: 0.25rem;
    }
    
    .value {
      font-weight: 600;
    }
  }
  
  .actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
`;

const ParametersCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
  }
  
  .operations-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .operation-item {
    padding: 1rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      border-color: var(--primary);
      background-color: rgba(59, 130, 246, 0.05);
    }
    
    &.active {
      border-color: var(--primary);
      background-color: rgba(59, 130, 246, 0.1);
    }
    
    .operation-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      
      .operation-name {
        font-weight: 600;
      }
      
      .operation-impact {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 0.2rem 0.5rem;
        border-radius: 12px;
        
        &.critical {
          background-color: rgba(239, 68, 68, 0.1);
          color: var(--error);
        }
        
        &.high {
          background-color: rgba(245, 158, 11, 0.1);
          color: var(--warning);
        }
        
        &.medium {
          background-color: rgba(59, 130, 246, 0.1);
          color: var(--primary);
        }
        
        &.low {
          background-color: rgba(16, 185, 129, 0.1);
          color: var(--success);
        }
      }
    }
    
    .operation-meta {
      display: flex;
      justify-content: space-between;
      font-size: 0.85rem;
      color: var(--text-light);
    }
  }
  
  .scenario-toggle {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .scenario-btn {
    padding: 0.5rem 1rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.9rem;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &.active {
      background-color: var(--primary);
      color: white;
      border-color: var(--primary);
    }
  }
`;

const ResultsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const ChartCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
  }
  
  .subtitle {
    font-size: 0.9rem;
    color: var(--text-light);
    margin-bottom: 1rem;
  }
`;

const KeyMetricsCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 1rem;
  }
  
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
  }
`;

const MetricCard = styled.div`
  background-color: ${props => props.highlighted ? 'rgba(59, 130, 246, 0.05)' : 'var(--background)'};
  border-radius: 0.375rem;
  padding: 1rem;
  border-left: 3px solid ${props => 
    props.trend === 'up' ? 'var(--error)' : 
    props.trend === 'down' ? 'var(--success)' : 
    'var(--primary)'
  };
  
  .metric-title {
    font-size: 0.8rem;
    color: var(--text-light);
    margin-bottom: 0.25rem;
  }
  
  .metric-value {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
  
  .metric-trend {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${props => 
      props.trend === 'up' ? 'var(--error)' : 
      props.trend === 'down' ? 'var(--success)' : 
      'var(--text-light)'
    };
  }
`;

const Slider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  background: #e5e7eb;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--primary);
    cursor: pointer;
  }
`;

const MapSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .subtitle {
    font-size: 0.95rem;
    color: var(--text-light);
    margin-bottom: 1rem;
  }
  
  .map-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .map-container {
    height: 400px;
    width: 100%;
    border-radius: 0.375rem;
    overflow: hidden;
  }
  
  .map-legend {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    
    .legend-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.85rem;
      
      .marker {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      
      .high {
        background-color: #ef4444;
      }
      
      .medium {
        background-color: #f59e0b;
      }
      
      .low {
        background-color: #10b981;
      }
      
      .supply-chain {
        background-color: #3b82f6;
      }
    }
  }
`;

const MapPlaceholder = styled.div`
  height: 400px;
  background-color: #e5e7eb;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light);
  font-size: 0.9rem;
`;

const RegulatoryTable = styled.div`
  margin-top: 1.5rem;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  overflow: hidden;
  
  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 0.75rem 1rem;
      text-align: left;
      font-size: 0.9rem;
    }
    
    th {
      background-color: var(--background);
      font-weight: 600;
      color: var(--text);
    }
    
    td {
      border-top: 1px solid var(--border);
    }
    
    td:last-child {
      font-weight: 500;
    }
    
    .status {
      display: inline-flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      
      &.at-risk {
        background-color: rgba(239, 68, 68, 0.1);
        color: var(--error);
      }
      
      &.compliance-needed {
        background-color: rgba(245, 158, 11, 0.1);
        color: var(--warning);
      }
      
      &.compliant {
        background-color: rgba(16, 185, 129, 0.1);
        color: var(--success);
      }
    }
  }
`;

const RecommendationsSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 1.5rem;
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .subtitle {
    font-size: 0.9rem;
    color: var(--text-light);
    margin-bottom: 1.5rem;
  }
`;

const ActionsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

const ActionCard = styled.div`
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  padding: 1.25rem;
  transition: all 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: var(--primary-light);
  }
  
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.75rem;
    color: var(--primary);
  }
  
  p {
    font-size: 0.9rem;
    color: var(--text);
    line-height: 1.5;
    margin: 0 0 1rem;
  }
  
  .impact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
    
    .impact-label {
      color: var(--text-light);
    }
    
    .impact-value {
      font-weight: 600;
      
      &.high {
        color: var(--success);
      }
      
      &.medium {
        color: var(--primary);
      }
      
      &.low {
        color: var(--text);
      }
    }
  }
  
  .deadline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    
    .deadline-label {
      color: var(--text-light);
    }
    
    .deadline-value {
      font-weight: 600;
    }
  }
`;

const ResilienceStrategiesSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .strategy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  
  .strategy-card {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1.25rem;
    
    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    .description {
      font-size: 0.9rem;
      margin-bottom: 1rem;
      color: var(--text);
    }
    
    .metrics {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.75rem;
      margin-bottom: 1rem;
      
      .metric {
        background-color: white;
        border-radius: 0.375rem;
        padding: 0.75rem;
        
        .label {
          font-size: 0.75rem;
          color: var(--text-light);
          margin-bottom: 0.25rem;
        }
        
        .value {
          font-size: 1rem;
          font-weight: 600;
          color: var(--text);
        }
      }
    }
    
    .actions {
      margin-top: 1rem;
      
      button {
        background-color: rgba(59, 130, 246, 0.1);
        color: var(--primary);
        border: none;
        padding: 0.5rem 0.75rem;
        border-radius: 0.375rem;
        font-size: 0.85rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        
        &:hover {
          background-color: rgba(59, 130, 246, 0.2);
        }
      }
    }
  }
`;

// Add a styled component for more detailed ecosystem parameter sliders
const ParameterSection = styled.div`
  margin-bottom: 1.5rem;
  
  .parameter-title {
    font-size: 0.95rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .parameter-description {
    font-size: 0.85rem;
    color: var(--text-light);
    margin-bottom: 0.75rem;
  }
  
  .parameter-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
    
    .value {
      font-size: 0.85rem;
      font-weight: 500;
      min-width: 60px;
      text-align: right;
    }
  }
  
  .parameter-labels {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: var(--text-light);
    margin-top: 0.25rem;
  }
`;

// Add the missing styled components after the MapSection styled component
const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const ChartDescription = styled.div`
  font-size: 0.85rem;
  color: var(--text-light);
  margin-bottom: 1rem;
`;

const ControlButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const IconButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: transparent;
  border: 1px solid var(--border);
  color: var(--text-light);
  cursor: pointer;
  
  &:hover {
    background-color: var(--background);
    color: var(--primary);
  }
`;

// Add these styled components after the ResilienceStrategiesSection styled component
const OperationsAnalysisSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0.5rem 0;
  }
  
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 1.5rem 0 0.75rem;
  }
  
  .subtitle {
    font-size: 0.95rem;
    color: var(--text-light);
    margin-bottom: 1.5rem;
  }
  
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
    
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    
    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
  
  .metric-card {
    background-color: var(--background);
    border-radius: 0.5rem;
    padding: 1rem;
    
    .metric-header {
      margin-bottom: 0.5rem;
      
      h4 {
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0;
        color: var(--text-light);
      }
    }
    
    .metric-value {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.25rem;
    }
    
    .metric-description {
      font-size: 0.85rem;
      color: var(--text-light);
    }
  }
  
  .services-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const ServiceCard = styled.div`
  background-color: ${props => 
    props.$risk === 'critical' ? 'rgba(239, 68, 68, 0.05)' : 
    props.$risk === 'high' ? 'rgba(245, 158, 11, 0.05)' : 
    props.$risk === 'medium' ? 'rgba(59, 130, 246, 0.05)' :
    'rgba(16, 185, 129, 0.05)'
  };
  border-left: 3px solid ${props => 
    props.$risk === 'critical' ? 'var(--error)' : 
    props.$risk === 'high' ? 'var(--warning)' : 
    props.$risk === 'medium' ? 'var(--primary)' :
    'var(--success)'
  };
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  
  .service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    
    h4 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }
  
  .service-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    
    .meta-item {
      display: flex;
      flex-direction: column;
      
      .label {
        font-size: 0.8rem;
        color: var(--text-light);
      }
      
      .value {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
  
  .impacts-list {
    margin-bottom: 1rem;
    
    h5 {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0 0 0.5rem;
    }
    
    ul {
      margin: 0;
      padding-left: 1.5rem;
      
      li {
        font-size: 0.85rem;
        margin-bottom: 0.25rem;
      }
    }
  }
  
  .financial-impacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    
    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    
    .cost-item {
      display: flex;
      flex-direction: column;
      
      .label {
        font-size: 0.8rem;
        color: var(--text-light);
      }
      
      .value {
        font-size: 0.9rem;
        font-weight: 500;
        
        &.highlight {
          color: ${props => 
            props.$risk === 'critical' ? 'var(--error)' : 
            props.$risk === 'high' ? 'var(--warning)' : 
            'inherit'
          };
          font-weight: 600;
        }
      }
    }
  }
`;

const StatusBadge = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  background-color: ${props => 
    props.$risk === 'critical' ? 'rgba(239, 68, 68, 0.1)' : 
    props.$risk === 'high' ? 'rgba(245, 158, 11, 0.1)' : 
    props.$risk === 'medium' ? 'rgba(59, 130, 246, 0.1)' :
    'rgba(16, 185, 129, 0.1)'
  };
  color: ${props => 
    props.$risk === 'critical' ? 'var(--error)' : 
    props.$risk === 'high' ? 'var(--warning)' : 
    props.$risk === 'medium' ? 'var(--primary)' :
    'var(--success)'
  };
`;

const OperationCard = styled.div`
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  padding: 1rem;
  transition: all 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: var(--primary-light);
  }
  
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 0.75rem;
    color: var(--primary);
  }
  
  p {
    font-size: 0.9rem;
    color: var(--text);
    line-height: 1.5;
    margin: 0 0 1rem;
  }
  
  .impact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
    
    .impact-label {
      color: var(--text-light);
    }
    
    .impact-value {
      font-weight: 600;
      
      &.high {
        color: var(--success);
      }
      
      &.medium {
        color: var(--primary);
      }
      
      &.low {
        color: var(--text);
      }
    }
  }
  
  .deadline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85rem;
    margin-top: 0.5rem;
    
    .deadline-label {
      color: var(--text-light);
    }
    
    .deadline-value {
      font-weight: 600;
    }
  }
`;

const OperationCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
`;

const OperationCardSummary = styled.div`
  font-size: 0.9rem;
  color: var(--text-light);
  margin-bottom: 0.5rem;
`;

const OperationCardImpacts = styled.div`
  font-size: 0.9rem;
  color: var(--text-light);
  margin-bottom: 0.5rem;
`;

const OperationCardView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
  color: var(--primary);
  cursor: pointer;
`;

// Add a FloatingHelpButton styled component
const FloatingHelpButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  z-index: 1000;
  
  &:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  svg {
    font-size: 1.5rem;
  }
`;

// Use a simpler tooltip that appears on hover
const InfoTooltip = styled.div`
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #10b981;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease;
  
  &:hover {
    opacity: 1;
  }
  
  &:hover:after {
    content: "${props => props.message || 'Click to explore ecosystem dependencies'}";
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #333;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
    width: 200px;
    font-size: 0.75rem;
    margin-top: 5px;
    z-index: 1000;
    white-space: normal;
  }
`;

const ResilienceSection = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 1.5rem;
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .subtitle {
    font-size: 0.95rem;
    color: var(--text-light);
    margin-bottom: 1rem;
  }
  
  .resilience-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .strategies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1rem;
  }
  
  .strategy-card {
    background-color: var(--background);
    border-radius: 0.375rem;
    padding: 1.25rem;
    border-left: 3px solid var(--primary);
    
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0 0 0.75rem;
      color: var(--primary);
    }
    
    p {
      font-size: 0.9rem;
      color: var(--text);
      line-height: 1.5;
      margin-bottom: 1rem;
    }
    
    .strategy-metrics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      
      .metric {
        .metric-label {
          font-size: 0.8rem;
          color: var(--text-light);
          margin-bottom: 0.25rem;
        }
        
        .metric-value {
          font-size: 0.9rem;
          font-weight: 500;
          
          &.success {
            color: var(--success);
          }
        }
      }
    }
  }
`;

const Simulations = () => {
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetOperations, setAssetOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedSimulation, setSelectedSimulation] = useState(null);
  const [currentSimulation, setCurrentSimulation] = useState(null);
  const [scenario, setScenario] = useState('moderate');
  const [activeMetric, setActiveMetric] = useState('ecosystem-value');
  const [showOperationsAnalysis, setShowOperationsAnalysis] = useState(false);
  
  // Simplified highlighting state - just auto-highlight when component mounts
  const [highlightedOperations, setHighlightedOperations] = useState(false);
  
  // Update the mock data to be assets rather than simulation scenarios
  // Replace simulationScenarios with assetList
  const assetList = [
    {
      id: 1,
      name: "Mountain Resort E",
      description: "Luxury mountain ski resort with restaurants, accommodations, and recreation facilities",
      type: "Hospitality",
      region: "Rocky Mountains",
      tags: ["Ski Resort", "Hospitality", "Tourism"],
      lastAnalysis: "2023-10-20",
      riskScore: 68,
      default: true
    },
    {
      id: 2,
      name: "Oceanside Hotel Complex",
      description: "Beachfront hotel with restaurants, spa, and water activities",
      type: "Hospitality",
      region: "Florida Coast",
      tags: ["Coastal", "Tourism", "Hospitality"],
      lastAnalysis: "2023-11-05",
      riskScore: 82
    },
    {
      id: 3,
      name: "Alpine Winery",
      description: "Vineyard and wine production facility in mountainous terrain",
      type: "Agriculture",
      region: "California",
      tags: ["Winery", "Agriculture", "Drought Risk"],
      lastAnalysis: "2023-09-15",
      riskScore: 56
    },
    {
      id: 4,
      name: "Urban Office Complex",
      description: "Downtown multi-tenant office building with retail spaces",
      type: "Commercial Real Estate",
      region: "Chicago",
      tags: ["Urban", "Office", "Retail"],
      lastAnalysis: "2023-10-10",
      riskScore: 42
    }
  ];
  
  // Change references to activeSimulation to activeAsset
  const [activeAsset, setActiveAsset] = useState(assetList[0]);
  const [simulationParameters, setSimulationParameters] = useState({
    temperatureIncrease: 2.5,
    oceanAcidification: 30,
    habitatLoss: 45,
    speciesResilience: 60
  });
  
  // Simple auto-highlight effect on first visit
  useEffect(() => {
    // Only highlight if this is user's first visit (use localStorage)
    const hasSeenHighlight = localStorage.getItem('mandjet_has_seen_highlight');
    
    if (!hasSeenHighlight) {
      // Show the highlight for the operations section
      setHighlightedOperations(true);
      
      // Auto-remove highlight after 5 seconds
      const timer = setTimeout(() => {
        setHighlightedOperations(false);
        localStorage.setItem('mandjet_has_seen_highlight', 'true');
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, []);
  
  // Reset operation selection when changing assets
  useEffect(() => {
    // Reset selected operation when changing assets
    setSelectedOperation(null);
    setShowOperationsAnalysis(false);
  }, [activeAsset]);
  
  // Define ecosystem dependencies for the Mountain Resort asset
  const mountainResortOperations = {
    "Restaurant Food Supply": {
      dependencies: 3,
      risk: "high",
      category: "Supply Chain"
    },
    "Water Management": {
      dependencies: 2,
      risk: "medium",
      category: "Operations"
    },
    "Energy Consumption": {
      dependencies: 4,
      risk: "high",
      category: "Energy"
    },
    "Winter Sports Activities": {
      dependencies: 3,
      risk: "critical",
      category: "Revenue"
    }
  };
  
  // Get ecosystem services for the selected operation
  const getEcosystemServices = (assetName, operationName) => {
    // Mock data for ecosystem services based on asset and operation
    const services = {
      "Mountain Resort E": {
        "Restaurant Food Supply": [
          { 
            id: 1, 
            name: "Wild Salmon", 
            source: "Pacific Northwest Rivers", 
            ecosystem: "Freshwater River Systems",
            risk: "high", 
            impacts: [
              "Warming water reduces spawning success",
              "Decreased water flow during drought periods",
              "Changing river chemistry affects navigation"
            ],
            baseline_cost: "$24/kg",
            projected_cost: "$38/kg (+58%)",
            availability: "Declining (65% of historical levels)"
          },
          { 
            id: 2, 
            name: "Mountain Mushrooms", 
            source: "Local Alpine Forests", 
            ecosystem: "Subalpine Mycological Systems",
            risk: "medium", 
            impacts: [
              "Shifting precipitation patterns affect growth cycles",
              "Temperature changes impact fruiting periods",
              "Forest composition changes alter habitat"
            ],
            baseline_cost: "$38/kg",
            projected_cost: "$52/kg (+37%)",
            availability: "Seasonal shifts (2-4 week earlier harvest)"
          },
          { 
            id: 3, 
            name: "Grass-Fed Beef", 
            source: "Regional Ranches", 
            ecosystem: "Alpine Meadows",
            risk: "medium", 
            impacts: [
              "Reduced meadow productivity during drought years",
              "Invasive species altering forage composition",
              "Heat stress affecting cattle health"
            ],
            baseline_cost: "$18/kg",
            projected_cost: "$26/kg (+44%)",
            availability: "Stable but with quality concerns"
          }
        ],
        "Water Management": [
          { 
            id: 1, 
            name: "Snowmelt", 
            source: "Alpine Snowpack", 
            ecosystem: "Mountain Hydrological Systems",
            risk: "critical", 
            impacts: [
              "32% reduction in snowpack depth by 2040",
              "Earlier spring melt (15-30 days)",
              "More precipitation as rain instead of snow"
            ],
            baseline_cost: "Minimal treatment required",
            projected_cost: "45% increase in supplemental sourcing",
            availability: "Highly variable and declining"
          },
          { 
            id: 2, 
            name: "Spring Water", 
            source: "Mountain Aquifers", 
            ecosystem: "Groundwater Systems",
            risk: "high", 
            impacts: [
              "Reduced recharge rates due to precipitation changes",
              "Competing demands from nearby communities",
              "Quality concerns from changing mineral content"
            ],
            baseline_cost: "$0.003/liter for extraction",
            projected_cost: "$0.008/liter (+167%)",
            availability: "25-40% reduction in flow rates"
          }
        ],
        "Energy Consumption": [
          { 
            id: 1, 
            name: "Hydroelectric Power", 
            source: "Regional Watersheds", 
            ecosystem: "River Systems",
            risk: "medium", 
            impacts: [
              "Reduced flow during summer months",
              "More variable precipitation affecting reliability",
              "Increased competition for water resources"
            ],
            baseline_cost: "$0.08/kWh",
            projected_cost: "$0.12/kWh (+50%)",
            availability: "Moderate decline expected (15-20%)"
          }
        ],
        "Winter Sports Activities": [
          { 
            id: 1, 
            name: "Skiable Terrain", 
            source: "Natural Snowfall", 
            ecosystem: "Alpine Snow Systems",
            risk: "critical", 
            impacts: [
              "25-40 day reduction in season length by 2040",
              "48% more snowmaking required annually",
              "Loss of lower-elevation terrain viability"
            ],
            baseline_cost: "$18,000/day for minimal snowmaking",
            projected_cost: "$42,000/day for extensive snowmaking (+133%)",
            availability: "Highly compromised (65% of historical season)"
          },
          { 
            id: 2, 
            name: "Cross-Country Trails", 
            source: "Forest Areas", 
            ecosystem: "Subalpine Forest Systems",
            risk: "high", 
            impacts: [
              "Unreliable snow cover in transitional months",
              "Forest fire risk increasing trail closures",
              "Vegetation changes affecting trail maintenance"
            ],
            baseline_cost: "$85,000 annual maintenance",
            projected_cost: "$145,000 annual maintenance (+71%)",
            availability: "30% fewer operational days"
          }
        ]
      },
      "Oceanside Hotel Complex": {
        "Seafood Restaurant Supply": [
          { 
            id: 1, 
            name: "Florida Lobster", 
            source: "Florida Keys", 
            ecosystem: "Coral Reef Systems",
            risk: "critical", 
            impacts: [
              "Coral habitat degradation due to bleaching",
              "Ocean acidification affecting shell development",
              "Shifting migration patterns due to warming"
            ],
            baseline_cost: "$32/kg",
            projected_cost: "$58/kg (+81%)",
            availability: "Rapidly declining (48% of historical catch)"
          },
          { 
            id: 2, 
            name: "Gulf Shrimp", 
            source: "Gulf of Mexico", 
            ecosystem: "Coastal Estuaries",
            risk: "high", 
            impacts: [
              "Increasing hypoxic zones reducing habitat",
              "Coastal erosion affecting breeding grounds",
              "Increased frequency of harmful algal blooms"
            ],
            baseline_cost: "$22/kg",
            projected_cost: "$35/kg (+59%)",
            availability: "Declining with higher variability between seasons"
          },
          { 
            id: 3, 
            name: "Yellowfin Tuna", 
            source: "Atlantic Ocean", 
            ecosystem: "Pelagic Marine Systems",
            risk: "medium", 
            impacts: [
              "Shifting migration routes due to ocean warming",
              "Changes in prey distribution",
              "Increasing storm activity disrupting fishing operations"
            ],
            baseline_cost: "$28/kg",
            projected_cost: "$39/kg (+39%)",
            availability: "Moderate decline with seasonal unpredictability"
          }
        ],
        "Building Cooling": [
          { 
            id: 1, 
            name: "Generic Resource", 
            source: "Regional Supply", 
            ecosystem: "Local Ecosystem",
            risk: "medium", 
            impacts: [
              "Temperature changes affecting availability",
              "Precipitation changes affecting quality",
              "Increased variability in supply"
            ],
            baseline_cost: "$10/unit",
            projected_cost: "$15/unit (+50%)",
            availability: "Moderate decline expected"
          }
        ],
        "Beach Activities": [
          { 
            id: 1, 
            name: "Beach Area", 
            source: "Coastal Shoreline", 
            ecosystem: "Coastal Beach Systems",
            risk: "critical", 
            impacts: [
              "Beach erosion from rising sea levels and storms",
              "Loss of 28-35% of beach area by 2040",
              "Increased frequency of beach closures due to extreme events"
            ],
            baseline_cost: "$120,000 annual beach maintenance",
            projected_cost: "$380,000 annual maintenance (+217%)",
            availability: "Significantly compromised with high variability"
          },
          { 
            id: 2, 
            name: "Coral Reef Tours", 
            source: "Offshore Reefs", 
            ecosystem: "Coral Reef Ecosystems",
            risk: "critical", 
            impacts: [
              "Severe coral bleaching affecting 65% of reef structures",
              "Species diversity decline reducing visual appeal",
              "Increasing storm damage to reef structures"
            ],
            baseline_cost: "$8,500/month in tour operations",
            projected_cost: "$7,800/month (-8% due to reduced demand)",
            availability: "Severely declining quality and visitor experience"
          }
        ],
        "Infrastructure": [
          { 
            id: 1, 
            name: "Generic Resource", 
            source: "Regional Supply", 
            ecosystem: "Local Ecosystem",
            risk: "medium", 
            impacts: [
              "Temperature changes affecting availability",
              "Precipitation changes affecting quality",
              "Increased variability in supply"
            ],
            baseline_cost: "$10/unit",
            projected_cost: "$15/unit (+50%)",
            availability: "Moderate decline expected"
          }
        ]
      },
      "Alpine Winery": {
        "Grape Production": [
          { 
            id: 1, 
            name: "Pinot Noir Grapes", 
            source: "Estate Vineyards", 
            ecosystem: "Mediterranean Climate Viticulture",
            risk: "critical", 
            impacts: [
              "Heat spikes during critical ripening periods",
              "Shifting seasonality affecting harvest timing",
              "Increased pest and disease pressure from range expansion"
            ],
            baseline_cost: "$4,800/ton",
            projected_cost: "$7,900/ton (+65%)",
            availability: "Declining quality with higher sugar/lower acid"
          },
          { 
            id: 2, 
            name: "Chardonnay Grapes", 
            source: "Valley Vineyards", 
            ecosystem: "Temperate Grape Growing Regions",
            risk: "high", 
            impacts: [
              "Earlier bud break exposing vines to spring frost",
              "Compressed harvest windows requiring more labor",
              "Water stress affecting yield and fruit composition"
            ],
            baseline_cost: "$3,200/ton",
            projected_cost: "$5,100/ton (+59%)",
            availability: "Moderate decline with vintage variability"
          },
          { 
            id: 3, 
            name: "Pollination Services", 
            source: "Native Bee Populations", 
            ecosystem: "Agricultural Pollinator Systems",
            risk: "medium", 
            impacts: [
              "Phenological mismatches between flowering and bee activity",
              "Declining native pollinator populations",
              "Increased competition with invasive insects"
            ],
            baseline_cost: "Natural service with supplemental hives ($3,500/year)",
            projected_cost: "$12,000/year for managed pollination",
            availability: "25-30% reduction in natural pollination services"
          }
        ],
        "Water Usage": [
          { 
            id: 1, 
            name: "Irrigation Water", 
            source: "Local Watershed", 
            ecosystem: "Riparian Water Systems",
            risk: "critical", 
            impacts: [
              "Declining summer streamflow by 35-45%",
              "Increased competition from upstream users",
              "Regulatory restrictions during drought periods"
            ],
            baseline_cost: "$180/acre-foot",
            projected_cost: "$620/acre-foot (+244%)",
            availability: "Severely restricted in 3 out of 5 growing seasons"
          },
          { 
            id: 2, 
            name: "Spring Water for Processing", 
            source: "Estate Springs", 
            ecosystem: "Groundwater Systems",
            risk: "high", 
            impacts: [
              "Reduced spring flow during summer months",
              "Increased mineral content affecting wine production",
              "More treatment required for wine production"
            ],
            baseline_cost: "$0.005/liter processed",
            projected_cost: "$0.014/liter processed (+180%)",
            availability: "Declining with seasonal reliability issues"
          }
        ],
        "Wine Processing": [
          { 
            id: 1, 
            name: "Generic Resource", 
            source: "Regional Supply", 
            ecosystem: "Local Ecosystem",
            risk: "medium", 
            impacts: [
              "Temperature changes affecting availability",
              "Precipitation changes affecting quality",
              "Increased variability in supply"
            ],
            baseline_cost: "$10/unit",
            projected_cost: "$15/unit (+50%)",
            availability: "Moderate decline expected"
          }
        ],
        "Tourism Activities": [
          { 
            id: 1, 
            name: "Vineyard Tours", 
            source: "Estate Landscape", 
            ecosystem: "Agricultural Tourism",
            risk: "medium", 
            impacts: [
              "Increased number of extreme heat days limiting tour windows",
              "Wildfire smoke affecting visitor experience and bookings",
              "Changes in fall foliage timing affecting peak tourism season"
            ],
            baseline_cost: "$45/visitor in operational costs",
            projected_cost: "$52/visitor (+16%)",
            availability: "30% reduction in prime visitation days"
          },
          { 
            id: 2, 
            name: "Outdoor Tasting Areas", 
            source: "Winery Grounds", 
            ecosystem: "Managed Landscape Systems",
            risk: "medium", 
            impacts: [
              "More frequent heat events reducing comfortable outdoor days",
              "Increased irrigation needs for landscape plants",
              "Changing pest pressures on ornamental species"
            ],
            baseline_cost: "$68,000 annual landscape maintenance",
            projected_cost: "$112,000 annual maintenance (+65%)",
            availability: "25% fewer suitable outdoor tasting days"
          }
        ]
      },
      "Urban Office Complex": {
        "Building Cooling": [
          { 
            id: 1, 
            name: "Green Space Cooling", 
            source: "Urban Tree Canopy", 
            ecosystem: "Urban Forest Systems",
            risk: "high", 
            impacts: [
              "Heat island intensification due to urban tree loss",
              "Reduced shading efficacy during extreme heat events",
              "Increased pest pressures on urban tree species"
            ],
            baseline_cost: "$72,000 annual cooling savings",
            projected_cost: "$42,000 annual cooling savings (-42%)",
            availability: "Declining functionality during heat waves"
          },
          { 
            id: 2, 
            name: "Water-Based Cooling", 
            source: "Local Reservoirs", 
            ecosystem: "Municipal Water Systems",
            risk: "medium", 
            impacts: [
              "Increased water stress during drought periods",
              "Higher water temperatures reducing cooling efficiency",
              "Competition with other municipal uses"
            ],
            baseline_cost: "$0.008/gallon for cooling operations",
            projected_cost: "$0.012/gallon (+50%)",
            availability: "Periodic restrictions during drought conditions"
          }
        ],
        "Energy Usage": [
          { 
            id: 1, 
            name: "Hydroelectric Power", 
            source: "Regional Watershed", 
            ecosystem: "River Basin Systems",
            risk: "high", 
            impacts: [
              "Reduced river flow affecting power generation",
              "Seasonal flow variability impacting reliability",
              "Increased sediment from extreme precipitation events"
            ],
            baseline_cost: "$0.062/kWh",
            projected_cost: "$0.078/kWh (+26%)",
            availability: "35% potential reduction during drought years"
          },
          { 
            id: 2, 
            name: "Biomass Energy", 
            source: "Regional Forests", 
            ecosystem: "Forest Management Systems",
            risk: "medium", 
            impacts: [
              "Changing forest composition affecting feedstock",
              "Increased wildfire disrupting supply chains",
              "Pest outbreaks impacting wood waste availability"
            ],
            baseline_cost: "$0.072/kWh",
            projected_cost: "$0.086/kWh (+19%)",
            availability: "Variable with 20-30% potential disruption"
          }
        ],
        "Water Management": [
          { 
            id: 1, 
            name: "Potable Water", 
            source: "Municipal Watershed", 
            ecosystem: "Reservoir and Aquifer Systems",
            risk: "medium", 
            impacts: [
              "Decreased snowpack reducing reservoir recharge",
              "More frequent drought affecting water quality",
              "Intensified precipitation causing runoff issues"
            ],
            baseline_cost: "$0.004/gallon",
            projected_cost: "$0.007/gallon (+75%)",
            availability: "Usage restrictions during drought periods"
          },
          { 
            id: 2, 
            name: "Stormwater Management", 
            source: "Urban Greenspace", 
            ecosystem: "Urban Drainage Systems",
            risk: "high", 
            impacts: [
              "More intense precipitation events exceeding capacity",
              "Reduced percolation from soil compaction and development",
              "Increased flooding risk from extreme weather"
            ],
            baseline_cost: "$85,000 annual infrastructure maintenance",
            projected_cost: "$145,000 annual maintenance (+71%)",
            availability: "System overwhelmed during major storm events"
          }
        ],
        "Retail Supply Chain": [
          { 
            id: 1, 
            name: "Cotton Textiles", 
            source: "Southeastern U.S. Farms", 
            ecosystem: "Agricultural Systems",
            risk: "high", 
            impacts: [
              "Increased water stress in growing regions",
              "More frequent pest outbreaks affecting yields",
              "Heat stress during critical growth periods"
            ],
            baseline_cost: "$4.20/pound",
            projected_cost: "$6.80/pound (+62%)",
            availability: "Declining quality and yield variability"
          },
          { 
            id: 2, 
            name: "Wood Products", 
            source: "Northern Forest Regions", 
            ecosystem: "Temperate Forest Systems",
            risk: "medium", 
            impacts: [
              "Changing species composition affecting timber quality",
              "Increased pest pressure reducing available stock",
              "More frequent wildfires disrupting supply"
            ],
            baseline_cost: "$3.40/board foot",
            projected_cost: "$4.75/board foot (+40%)",
            availability: "Periodic shortages with quality concerns"
          },
          { 
            id: 3, 
            name: "Fresh Produce", 
            source: "Regional Agricultural Areas", 
            ecosystem: "Agricultural Ecosystems",
            risk: "critical", 
            impacts: [
              "Reduced crop yields from heat stress",
              "Increased water requirements during drought",
              "Shifting growing seasons affecting availability"
            ],
            baseline_cost: "$1.80/pound average",
            projected_cost: "$3.10/pound average (+72%)",
            availability: "Highly variable with seasonal disruptions"
          }
        ]
      }
    };

    // Return the services for this asset/operation if they exist
    if (services[assetName] && services[assetName][operationName]) {
      return services[assetName][operationName];
    }
    
    // Default mock data if specific services not found
    return [
      { 
        id: 1, 
        name: "Generic Resource", 
        source: "Regional Supply", 
        ecosystem: "Local Ecosystem",
        risk: "medium", 
        impacts: [
          "Temperature changes affecting availability",
          "Precipitation changes affecting quality",
          "Increased variability in supply"
        ],
        baseline_cost: "$10/unit",
        projected_cost: "$15/unit (+50%)",
        availability: "Moderate decline expected"
      }
    ];
  };

  // Get active operations for this asset
  const activeOperations = (() => {
    if (activeAsset.name === "Mountain Resort E") {
      return {
        "Food & Beverage": [
          {
            id: 1,
            name: "Restaurant Food Supply",
            ecosystemDependencies: 3,
            operationalValue: 4.2,
            risk: "high",
            summary: "Farm-to-table restaurant relying on local sustainable agriculture",
            topImpacts: [
              "Local food supply disruption due to changing growing seasons",
              "Price volatility from climate-induced crop failures",
              "Water scarcity affecting food production"
            ]
          }
        ],
        "Water Management": [
          {
            id: 2,
            name: "Water Management",
            ecosystemDependencies: 2,
            operationalValue: 3.8,
            risk: "medium",
            summary: "Water-intensive operations for snowmaking and landscaping",
            topImpacts: [
              "Decreasing snowpack reducing natural water storage",
              "Earlier spring runoff changing water availability timing",
              "Competing water demands during drought periods"
            ]
          }
        ],
        "Recreation": [
          {
            id: 3,
            name: "Winter Sports Activities",
            ecosystemDependencies: 2,
            operationalValue: 8.6,
            risk: "critical",
            summary: "Ski slopes, cross-country trails, and winter activities",
            topImpacts: [
              "Shortened ski season from rising temperatures",
              "Unpredictable snow conditions increasing operational costs",
              "Guest experience degradation from artificial snow reliance"
            ]
          },
          {
            id: 4,
            name: "Energy Consumption",
            ecosystemDependencies: 1,
            operationalValue: 3.2,
            risk: "medium",
            summary: "Energy use for heating, cooling and resort operations",
            topImpacts: [
              "Hydropower disruption from changing precipitation patterns",
              "Increased cooling demands from rising temperatures",
              "Grid instability from extreme weather events"
            ]
          }
        ]
      };
    } else if (activeAsset.name === "Oceanside Hotel Complex") {
      return {
        "Food & Beverage": [
          {
            id: 1,
            name: "Seafood Restaurant Supply",
            ecosystemDependencies: 3,
            operationalValue: 5.8,
            risk: "critical",
            summary: "Fresh seafood restaurant specializing in local catch",
            topImpacts: [
              "Declining fish stocks from ocean warming",
              "Coral reef degradation affecting local species",
              "Harmful algal blooms contaminating shellfish"
            ]
          }
        ],
        "Building Operations": [
          {
            id: 2,
            name: "Building Cooling",
            ecosystemDependencies: 1,
            operationalValue: 2.4,
            risk: "medium",
            summary: "HVAC systems for guest comfort in increasingly hot climate",
            topImpacts: [
              "Rising cooling demand from temperature increases",
              "Power supply disruptions during extreme weather",
              "Increased maintenance from system stress"
            ]
          },
          {
            id: 3,
            name: "Infrastructure",
            ecosystemDependencies: 1,
            operationalValue: 3.6,
            risk: "medium",
            summary: "Property infrastructure maintenance and protection",
            topImpacts: [
              "Erosion threatening building foundations",
              "Salt water intrusion affecting utilities",
              "Storm surge impacting property integrity"
            ]
          }
        ],
        "Guest Activities": [
          {
            id: 4,
            name: "Beach Activities",
            ecosystemDependencies: 2,
            operationalValue: 7.2,
            risk: "critical",
            summary: "Guest beach access, swimming, and water sports",
            topImpacts: [
              "Beach erosion from sea level rise and storms",
              "Water quality issues from increased runoff",
              "Coral reef degradation affecting snorkeling experiences"
        ]
      }
    ]
  };
    } else if (activeAsset.name === "Alpine Winery") {
      return {
        "Production": [
          {
            id: 1,
            name: "Grape Production",
            ecosystemDependencies: 3,
            operationalValue: 6.8,
            risk: "critical",
            summary: "Vineyard management and grape cultivation",
            topImpacts: [
              "Shifting growing seasons affecting grape quality",
              "Increased water stress during critical periods",
              "New pest and disease pressures in warming climate"
            ]
          },
          {
            id: 2,
            name: "Water Usage",
            ecosystemDependencies: 2,
            operationalValue: 4.2,
            risk: "critical",
            summary: "Irrigation and wine production water needs",
            topImpacts: [
              "Reduced water availability during growing season",
              "Competing demands from other agricultural users",
              "Regulatory restrictions during drought periods"
            ]
          },
          {
            id: 3,
            name: "Wine Processing",
            ecosystemDependencies: 1,
            operationalValue: 3.1,
            risk: "medium",
            summary: "Production facility operations and wine making",
            topImpacts: [
              "Temperature control challenges during heat waves",
              "Energy supply disruptions affecting production",
              "Water quality changes impacting wine production"
            ]
          }
        ],
        "Visitor Experience": [
          {
            id: 4,
            name: "Tourism Activities",
            ecosystemDependencies: 2,
            operationalValue: 2.9,
            risk: "medium",
            summary: "Wine tasting, tours, and visitor amenities",
            topImpacts: [
              "Wildfire smoke affecting visitor experience",
              "Extreme heat limiting outdoor activities",
              "Changing seasonal patterns affecting tourism flow"
            ]
          }
        ]
      };
    } else if (activeAsset.name === "Urban Office Complex") {
      return {
        "Building Systems": [
          {
            id: 1,
            name: "Building Cooling",
            ecosystemDependencies: 2,
            operationalValue: 3.8,
            risk: "high",
            summary: "HVAC systems for temperature regulation in multi-tenant office building",
            topImpacts: [
              "Heat island effect intensification in urban area",
              "Rising cooling demands during extreme heat events",
              "Increased operational costs from energy price volatility"
            ]
          },
          {
            id: 2,
            name: "Energy Usage",
            ecosystemDependencies: 2,
            operationalValue: 4.2,
            risk: "high",
            summary: "Power for building operations, tenants, and retail spaces",
            topImpacts: [
              "Grid instability during extreme weather events",
              "Reduced hydroelectric reliability during drought periods",
              "Increased peak demand challenges during heat waves"
            ]
          }
        ],
        "Infrastructure": [
          {
            id: 3,
            name: "Water Management",
            ecosystemDependencies: 2,
            operationalValue: 2.6,
            risk: "medium",
            summary: "Water supply and stormwater management systems",
            topImpacts: [
              "Urban flooding risks during intense precipitation",
              "Water supply restrictions during drought periods",
              "Increased maintenance costs from extreme weather"
            ]
          }
        ],
        "Tenant Operations": [
          {
            id: 4,
            name: "Retail Supply Chain",
            ecosystemDependencies: 3,
            operationalValue: 5.4,
            risk: "critical",
            summary: "Supply chain for retail tenants in the complex",
            topImpacts: [
              "Agricultural product disruptions affecting retail inventory",
              "Transportation disruptions during extreme weather",
              "Price volatility for climate-sensitive products"
            ]
          }
        ]
      };
    }
    
    // Default mock data
    return {
      "Operations": [
        {
          id: 1,
          name: "General Operations",
          ecosystemDependencies: 2,
          operationalValue: 3.5,
          risk: "medium",
          summary: "Standard operational activities",
          topImpacts: [
            "Resource supply disruptions",
            "Increased operational costs",
            "Regulatory changes related to climate adaptation"
          ]
        }
      ]
    };
  })();
  
  const handleSelectOperation = (operation) => {
    setSelectedOperation(operation);
    setShowOperationsAnalysis(true);
    setHighlightedOperations(false); // Clear highlight when an operation is selected
  };

  return (
    <PageContainer>
      <PageHeader>
        <div>
          <PageTitle>Ecosystem Services Risk Analysis</PageTitle>
          <PageDescription>
            Analyze how climate change affects the ecosystem services your assets depend on,
            quantifying impacts on natural resources, biological systems, and ecological processes that support operations.
          </PageDescription>
        </div>
        <div>
          <ActionButton primary onClick={() => {}}>
            <FiPlay size={16} /> Run Simulation
          </ActionButton>
        </div>
      </PageHeader>
      
      <ContentGrid>
        <SimulationSidebar className="simulation-sidebar">
          <SidebarHeader>
            <span>Assets</span>
            <FiPlusCircle size={16} style={{ cursor: 'pointer' }} />
          </SidebarHeader>
          <SimulationList>
            {assetList.map(asset => (
              <SimulationItem 
                key={asset.id} 
                className={activeAsset.id === asset.id ? 'active' : ''}
                onClick={() => setActiveAsset(asset)}
              >
                <div className="name">{asset.name}</div>
                <div className="meta">
                  Type: {asset.type} | Region: {asset.region}
                </div>
                <div className="meta">
                  <span style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                    color: asset.riskScore > 75 ? 'var(--error)' : 
                          asset.riskScore > 50 ? 'var(--warning)' : 
                          'var(--success)'
                  }}>
                    <FiAlertTriangle size={14} /> 
                    Risk Score: {asset.riskScore}
                  </span>
                </div>
                <div className="tags">
                  {asset.tags.map((tag, index) => (
                    <span key={index} className="tag">{tag}</span>
                  ))}
                </div>
              </SimulationItem>
            ))}
          </SimulationList>
        </SimulationSidebar>
        
        {selectedOperation && showOperationsAnalysis ? (
          <SimulationContent>
            <OperationsAnalysisSection>
              <h3>
                <span onClick={() => setShowOperationsAnalysis(false)} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                  <FiArrowLeft style={{ marginRight: '0.5rem' }} /> Back to Overview
                </span>
              </h3>
              
              <h2>Operations Analysis: {selectedOperation.name}</h2>
              <div className="subtitle">
                Detailed analysis of ecosystem dependencies for this operational area
              </div>
              
              <div className="metrics-grid">
                <div className="metric-card">
                  <div className="metric-header">
                    <h4>Ecosystem Services</h4>
                  </div>
                  <div className="metric-value">{getEcosystemServices(activeAsset.name, selectedOperation.name).length}</div>
                  <div className="metric-description">Supporting this operation</div>
                </div>
                
                <div className="metric-card">
                  <div className="metric-header">
                    <h4>Critical Dependencies</h4>
                  </div>
                  <div className="metric-value">
                    {getEcosystemServices(activeAsset.name, selectedOperation.name)
                      .filter(service => service.risk === 'critical').length}
                  </div>
                  <div className="metric-description">At risk of severe disruption</div>
                </div>
                
                <div className="metric-card">
                  <div className="metric-header">
                    <h4>Climate Hazards</h4>
                  </div>
                  <div className="metric-value">
                    {getEcosystemServices(activeAsset.name, selectedOperation.name)
                      .reduce((total, service) => total + service.impacts.length, 0)}
                  </div>
                  <div className="metric-description">Affecting these services</div>
                </div>
                
                <div className="metric-card">
                  <div className="metric-header">
                    <h4>Value at Risk</h4>
                  </div>
                  <div className="metric-value">
                    ${(() => {
                      // Calculate approximate value at risk
                      const services = getEcosystemServices(activeAsset.name, selectedOperation.name);
                      const criticalServices = services.filter(s => s.risk === 'critical').length;
                      const highRiskServices = services.filter(s => s.risk === 'high').length;
                      return ((criticalServices * 2.5) + (highRiskServices * 1.2)).toFixed(1);
                    })()}M
                  </div>
                  <div className="metric-description">From ecosystem disruption</div>
                </div>
              </div>
              
              <div className="services-list">
                <h3>Ecosystem Services Supporting {selectedOperation.name}</h3>
                
                {getEcosystemServices(activeAsset.name, selectedOperation.name).map((service, index) => (
                  <ServiceCard 
                    key={service.id} 
                    $risk={service.risk}
                    className={index === 0 ? "service-card-first" : ""}
                  >
                    <div className="service-header">
                      <h4>{service.name}</h4>
                      <StatusBadge $risk={service.risk}>
                        {service.risk.charAt(0).toUpperCase() + service.risk.slice(1)}
                      </StatusBadge>
                    </div>
                    
                    <div className="service-meta">
                      <div className="meta-item">
                        <span className="label">Source:</span>
                        <span className="value">{service.source}</span>
                      </div>
                      <div className="meta-item">
                        <span className="label">Ecosystem:</span>
                        <span className="value">{service.ecosystem}</span>
                      </div>
                    </div>
                    
                    <div className="impacts-list">
                      <h5>Climate Impacts</h5>
                      <ul>
                        {service.impacts.map((impact, i) => (
                          <li key={i}>{impact}</li>
                        ))}
                      </ul>
                    </div>
                    
                    <div className="financial-impacts">
                      <div className="cost-item">
                        <span className="label">Baseline Cost:</span>
                        <span className="value">{service.baseline_cost}</span>
                      </div>
                      <div className="cost-item">
                        <span className="label">Projected Cost:</span>
                        <span className="value highlight" $risk={service.risk}>{service.projected_cost}</span>
                      </div>
                      <div className="cost-item">
                        <span className="label">Availability:</span>
                        <span className="value highlight" $risk={service.risk}>{service.availability}</span>
                      </div>
                    </div>
                  </ServiceCard>
                ))}
              </div>
            </OperationsAnalysisSection>
          </SimulationContent>
        ) : (
          <SimulationContent>
            {/* Asset Overview Section */}
            <SimulationHeader>
              <h2>{activeAsset.name}</h2>
              <div className="description">{activeAsset.description}</div>
              
              <div className="meta-row">
                <div className="meta-item">
                  <span className="label">Asset Type</span>
                  <span className="value">{activeAsset.type}</span>
                </div>
                <div className="meta-item">
                  <span className="label">Region</span>
                  <span className="value">{activeAsset.region}</span>
                </div>
                <div className="meta-item">
                  <span className="label">Last Analysis</span>
                  <span className="value">{activeAsset.lastAnalysis}</span>
                </div>
                <div className="meta-item">
                  <span className="label">Risk Score</span>
                  <span className="value" style={{
                    color: activeAsset.riskScore > 75 ? 'var(--error)' : 
                          activeAsset.riskScore > 50 ? 'var(--warning)' : 
                          'var(--success)'
                  }}>{activeAsset.riskScore}/100</span>
                </div>
              </div>
              
              <div className="actions">
                <ActionButton>
                  <FiCopy size={16} /> Duplicate
                </ActionButton>
                <ActionButton>
                  <FiSave size={16} /> Save
                </ActionButton>
                <ActionButton primary>
                  <FiDownload size={16} /> Export Report
                </ActionButton>
              </div>
            </SimulationHeader>
            
            {/* Operations & Ecosystem Dependencies Section with Feature Highlight */}
            <FeatureHighlight 
              featureId="ecosystem-dependencies"
              tooltip="Explore how your operations depend on ecosystem services that may be affected by climate change"
              duration={5000}
            >
              <ParametersCard id="operations-section">
              <h3>Operations & Ecosystem Dependencies</h3>
                <div style={{ fontSize: '0.95rem', color: 'var(--text-light)', marginTop: '0.5rem', marginBottom: '1rem' }}>
                Select an operational area to see detailed ecosystem service dependencies and impacts.
                </div>

              <div className="operations-grid">
                  {activeAsset.name === "Mountain Resort E" && (
                    <>
                      {/* Mountain Resort E Operations */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 1,
                        name: "Restaurant Food Supply",
                        ecosystemDependencies: 3,
                        operationalValue: 4.2,
                        risk: "high",
                        summary: "Farm-to-table restaurant relying on local sustainable agriculture",
                        topImpacts: [
                          "Local food supply disruption due to changing growing seasons",
                          "Price volatility from climate-induced crop failures",
                          "Water scarcity affecting food production"
                        ]
                      })}>
                    <div className="operation-header">
                          <div className="operation-name">Restaurant Food Supply</div>
                          <div className="operation-impact high">High</div>
                    </div>
                    <div className="operation-meta">
                          <div>Supply Chain</div>
                          <div>3 ecosystem dependencies</div>
                      </div>
                    </div>
                      
                      {/* Water Management */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 2,
                        name: "Water Management",
                        ecosystemDependencies: 2,
                        operationalValue: 3.8,
                        risk: "medium",
                        summary: "Water-intensive operations for snowmaking and landscaping",
                        topImpacts: [
                          "Decreasing snowpack reducing natural water storage",
                          "Earlier spring runoff changing water availability timing",
                          "Competing water demands during drought periods"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Water Management</div>
                          <div className="operation-impact medium">Medium</div>
                  </div>
                        <div className="operation-meta">
                          <div>Operations</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
              </div>
              
                      {/* Energy Consumption */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 3,
                        name: "Energy Consumption",
                        ecosystemDependencies: 1,
                        operationalValue: 5.1,
                        risk: "high",
                        summary: "Energy use for heating, cooling, and operations",
                        topImpacts: [
                          "Hydropower disruption from changing precipitation patterns",
                          "Increased cooling demands from rising temperatures",
                          "Grid instability from extreme weather events"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Energy Consumption</div>
                          <div className="operation-impact high">High</div>
                        </div>
                        <div className="operation-meta">
                          <div>Energy</div>
                          <div>1 ecosystem dependency</div>
                        </div>
                      </div>
                      
                      {/* Winter Sports Activities */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 4,
                        name: "Winter Sports Activities",
                        ecosystemDependencies: 2,
                        operationalValue: 8.6,
                        risk: "critical",
                        summary: "Ski slopes, cross-country trails, and winter activities",
                        topImpacts: [
                          "Shortened ski season from rising temperatures",
                          "Unpredictable snow conditions increasing operational costs",
                          "Guest experience degradation from artificial snow reliance"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Winter Sports Activities</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Revenue</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                    </>
                  )}

                  {activeAsset.name === "Oceanside Hotel Complex" && (
                    <>
                      {/* Seafood Restaurant Supply */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 1,
                        name: "Seafood Restaurant Supply",
                        ecosystemDependencies: 3,
                        operationalValue: 5.8,
                        risk: "critical",
                        summary: "Fresh seafood restaurant specializing in local catch",
                        topImpacts: [
                          "Declining fish stocks from ocean warming",
                          "Coral reef degradation affecting local species",
                          "Harmful algal blooms contaminating shellfish"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Seafood Restaurant Supply</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Food & Beverage</div>
                          <div>3 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Building Cooling */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 2,
                        name: "Building Cooling",
                        ecosystemDependencies: 1,
                        operationalValue: 2.4,
                        risk: "medium",
                        summary: "HVAC systems for guest comfort in increasingly hot climate",
                        topImpacts: [
                          "Rising cooling demand from temperature increases",
                          "Power supply disruptions during extreme weather",
                          "Increased maintenance from system stress"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Building Cooling</div>
                          <div className="operation-impact medium">Medium</div>
                        </div>
                        <div className="operation-meta">
                          <div>Building Operations</div>
                          <div>1 ecosystem dependency</div>
                        </div>
                      </div>
                      
                      {/* Infrastructure */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 3,
                        name: "Infrastructure",
                        ecosystemDependencies: 1,
                        operationalValue: 3.6,
                        risk: "medium",
                        summary: "Property infrastructure maintenance and protection",
                        topImpacts: [
                          "Erosion threatening building foundations",
                          "Salt water intrusion affecting utilities",
                          "Storm surge impacting property integrity"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Infrastructure</div>
                          <div className="operation-impact medium">Medium</div>
                        </div>
                        <div className="operation-meta">
                          <div>Building Operations</div>
                          <div>1 ecosystem dependency</div>
                        </div>
                      </div>
                      
                      {/* Beach Activities */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 4,
                        name: "Beach Activities",
                        ecosystemDependencies: 2,
                        operationalValue: 7.2,
                        risk: "critical",
                        summary: "Guest beach access, swimming, and water sports",
                        topImpacts: [
                          "Beach erosion from sea level rise and storms",
                          "Water quality issues from increased runoff",
                          "Coral reef degradation affecting snorkeling experiences"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Beach Activities</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Guest Activities</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                    </>
                  )}
                  
                  {activeAsset.name === "Alpine Winery" && (
                    <>
                      {/* Grape Production */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 1,
                        name: "Grape Production",
                        ecosystemDependencies: 3,
                        operationalValue: 6.8,
                        risk: "critical",
                        summary: "Vineyard management and grape cultivation",
                        topImpacts: [
                          "Shifting growing seasons affecting grape quality",
                          "Increased water stress during critical periods",
                          "New pest and disease pressures in warming climate"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Grape Production</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Production</div>
                          <div>3 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Water Usage */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 2,
                        name: "Water Usage",
                        ecosystemDependencies: 2,
                        operationalValue: 4.2,
                        risk: "critical",
                        summary: "Irrigation and wine production water needs",
                        topImpacts: [
                          "Reduced water availability during growing season",
                          "Competing demands from other agricultural users",
                          "Regulatory restrictions during drought periods"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Water Usage</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Production</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Wine Processing */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 3,
                        name: "Wine Processing",
                        ecosystemDependencies: 1,
                        operationalValue: 3.1,
                        risk: "medium",
                        summary: "Production facility operations and wine making",
                        topImpacts: [
                          "Temperature control challenges during heat waves",
                          "Energy supply disruptions affecting production",
                          "Water quality changes impacting wine production"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Wine Processing</div>
                          <div className="operation-impact medium">Medium</div>
                        </div>
                        <div className="operation-meta">
                          <div>Production</div>
                          <div>1 ecosystem dependency</div>
                        </div>
                      </div>
                      
                      {/* Tourism Activities */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 4,
                        name: "Tourism Activities",
                        ecosystemDependencies: 2,
                        operationalValue: 2.9,
                        risk: "medium",
                        summary: "Wine tasting, tours, and visitor amenities",
                        topImpacts: [
                          "Wildfire smoke affecting visitor experience",
                          "Extreme heat limiting outdoor activities",
                          "Changing seasonal patterns affecting tourism flow"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Tourism Activities</div>
                          <div className="operation-impact medium">Medium</div>
                        </div>
                        <div className="operation-meta">
                          <div>Visitor Experience</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                    </>
                  )}
                  
                  {activeAsset.name === "Urban Office Complex" && (
                    <>
                      {/* Building Cooling */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 1,
                        name: "Building Cooling",
                        ecosystemDependencies: 2,
                        operationalValue: 3.8,
                        risk: "high",
                        summary: "HVAC systems for temperature regulation in multi-tenant office building",
                        topImpacts: [
                          "Heat island effect intensification in urban area",
                          "Rising cooling demands during extreme heat events",
                          "Increased operational costs from energy price volatility"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Building Cooling</div>
                          <div className="operation-impact high">High</div>
                        </div>
                        <div className="operation-meta">
                          <div>Building Systems</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Energy Usage */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 2,
                        name: "Energy Usage",
                        ecosystemDependencies: 2,
                        operationalValue: 4.2,
                        risk: "high",
                        summary: "Power for building operations, tenants, and retail spaces",
                        topImpacts: [
                          "Grid instability during extreme weather events",
                          "Reduced hydroelectric reliability during drought periods",
                          "Increased peak demand challenges during heat waves"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Energy Usage</div>
                          <div className="operation-impact high">High</div>
                        </div>
                        <div className="operation-meta">
                          <div>Building Systems</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Water Management */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 3,
                        name: "Water Management",
                        ecosystemDependencies: 2,
                        operationalValue: 2.6,
                        risk: "medium",
                        summary: "Water supply and stormwater management systems",
                        topImpacts: [
                          "Urban flooding risks during intense precipitation",
                          "Water supply restrictions during drought periods",
                          "Increased maintenance costs from extreme weather"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Water Management</div>
                          <div className="operation-impact medium">Medium</div>
                        </div>
                        <div className="operation-meta">
                          <div>Infrastructure</div>
                          <div>2 ecosystem dependencies</div>
                        </div>
                      </div>
                      
                      {/* Retail Supply Chain */}
                      <div className="operation-item" onClick={() => handleSelectOperation({
                        id: 4,
                        name: "Retail Supply Chain",
                        ecosystemDependencies: 3,
                        operationalValue: 5.4,
                        risk: "critical",
                        summary: "Supply chain for retail tenants in the complex",
                        topImpacts: [
                          "Agricultural product disruptions affecting retail inventory",
                          "Transportation disruptions during extreme weather",
                          "Price volatility for climate-sensitive products"
                        ]
                      })}>
                        <div className="operation-header">
                          <div className="operation-name">Retail Supply Chain</div>
                          <div className="operation-impact critical">Critical</div>
                        </div>
                        <div className="operation-meta">
                          <div>Tenant Operations</div>
                          <div>3 ecosystem dependencies</div>
                        </div>
                      </div>
                    </>
                  )}
                  
                  {!["Mountain Resort E", "Oceanside Hotel Complex", "Alpine Winery", "Urban Office Complex"].includes(activeAsset.name) && (
                    <div className="operation-item" onClick={() => handleSelectOperation({
                      id: 1,
                      name: "General Operations",
                      ecosystemDependencies: 2,
                      operationalValue: 3.5,
                      risk: "medium",
                      summary: "Standard operational activities",
                      topImpacts: [
                        "Resource supply disruptions",
                        "Increased operational costs",
                        "Regulatory changes related to climate adaptation"
                      ]
                    })}>
                      <div className="operation-header">
                        <div className="operation-name">General Operations</div>
                        <div className="operation-impact medium">Medium</div>
                      </div>
                      <div className="operation-meta">
                        <div>Operations</div>
                        <div>2 ecosystem dependencies</div>
                      </div>
                    </div>
                  )}
              </div>
            </ParametersCard>
            </FeatureHighlight>
            
            {/* Ecosystem Service Locations section */}
            <MapSection>
              <h2>
                <FiMap size={18} style={{ marginRight: '0.5rem' }} />
                Ecosystem Service Locations & Supply Networks
              </h2>
              
              <div className="subtitle">
                Geographic visualization of natural systems supporting {activeAsset.name}
              </div>
              
              <div className="map-actions">
                <ActionButton>
                  <FiDownload size={16} /> Export Map
                </ActionButton>
              </div>
              
                <MapPlaceholder>
                <div style={{ textAlign: 'center' }}>
                  <FiMap size={48} style={{ opacity: 0.5, marginBottom: '1rem' }} />
                  <div>Ecosystem Dependencies & Supply Chain Map visualization would appear here</div>
              </div>
              </MapPlaceholder>
              
              <div className="map-legend">
                <div className="legend-item">
                  <span className="marker high"></span>
                  <span>High Risk Source</span>
                          </div>
                <div className="legend-item">
                  <span className="marker medium"></span>
                  <span>Medium Risk Source</span>
                </div>
                <div className="legend-item">
                  <span className="marker low"></span>
                  <span>Low Risk Source</span>
                </div>
                <div className="legend-item">
                  <span className="marker supply-chain"></span>
                  <span>Supply Chain Connection</span>
                </div>
              </div>
            </MapSection>
            
            {/* Resilience Strategies section */}
            <ResilienceSection>
              <h2>
                <FiRefreshCw size={18} style={{ marginRight: '0.5rem' }} />
                Ecosystem Service Resilience Strategies
              </h2>
              
              <div className="subtitle">
                Comprehensive ecosystem service adaptation strategies for {activeAsset.name}
              </div>
              
              <div className="resilience-actions">
                <ActionButton>
                  <FiDownload size={16} /> Export Plan
                </ActionButton>
              </div>
              
              <div className="strategies-grid">
                {activeAsset.name === "Mountain Resort E" ? (
                  <>
                    <div className="strategy-card">
                      <h3>Alpine Snowpack Resilience Program</h3>
                      <p>
                        Develop comprehensive climate adaptation strategies for winter operations dependent on natural snowfall, including snowmaking efficiency and alternative winter activities.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$4.2M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Immediate (Q2-Q4 2024)</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="strategy-card">
                      <h3>Mountain Watershed Conservation Initiative</h3>
                      <p>
                        Invest in upstream watershed protection and water storage solutions to secure year-round water availability for resort operations and ecosystem health.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$3.5M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Short-term (Q1 2025)</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : activeAsset.name === "Oceanside Hotel Complex" ? (
                  <>
                    <div className="strategy-card">
                      <h3>Ecosystem Services Protection Plan</h3>
                      <p>
                        Develop a comprehensive strategy to protect and enhance the ecosystem services that this asset depends on, focusing on the most critical dependencies.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$3.5M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Short-term (Q1-Q2 2025)</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="strategy-card">
                      <h3>Climate Adaptation Investment Strategy</h3>
                      <p>
                        Allocate capital investment toward climate adaptation measures that will ensure continued delivery of critical ecosystem services.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">Medium (+$2.3M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Medium-term (2025-2027)</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : activeAsset.name === "Alpine Winery" ? (
                  <>
                    <div className="strategy-card">
                      <h3>Climate-Resilient Viticulture Program</h3>
                      <p>
                        Implement a comprehensive climate resilience program focused on heat, drought, and pest resistant grape varieties, along with improved irrigation efficiency.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$3.8M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Medium-term (2024-2026)</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="strategy-card">
                      <h3>Pollinator Habitat Conservation Network</h3>
                      <p>
                        Develop and protect native pollinator habitat throughout the vineyard property and surrounding areas to ensure ongoing ecosystem services for grape production.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$2.7M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Start Q3 2024</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : activeAsset.name === "Urban Office Complex" ? (
                  <>
                    <div className="strategy-card">
                      <h3>Ecosystem Services Protection Plan</h3>
                      <p>
                        Develop a comprehensive strategy to protect and enhance the ecosystem services that this asset depends on, focusing on the most critical dependencies.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">High (+$3.5M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Short-term (Q1-Q2 2025)</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="strategy-card">
                      <h3>Climate Adaptation Investment Strategy</h3>
                      <p>
                        Allocate capital investment toward climate adaptation measures that will ensure continued delivery of critical ecosystem services.
                      </p>
                      
                      <div className="strategy-metrics">
                        <div className="metric">
                          <div className="metric-label">Value Protection</div>
                          <div className="metric-value success">Medium (+$2.3M)</div>
                        </div>
                        <div className="metric">
                          <div className="metric-label">Implementation Timeline</div>
                          <div className="metric-value">Medium-term (2025-2027)</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="strategy-card">
                    <h3>General Resilience Strategy</h3>
                    <p>
                      Implement climate adaptation measures specific to the asset type and region to protect against climate-related disruptions.
                    </p>
                    
                    <div className="strategy-metrics">
                      <div className="metric">
                        <div className="metric-label">Value Protection</div>
                        <div className="metric-value success">Medium</div>
                      </div>
                      <div className="metric">
                        <div className="metric-label">Implementation Timeline</div>
                        <div className="metric-value">Medium-term</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ResilienceSection>
          </SimulationContent>
        )}
      </ContentGrid>
    </PageContainer>
  );
};

export default Simulations; 