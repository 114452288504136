import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiLock, FiUser, FiAlertTriangle } from 'react-icons/fi';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background);
  padding: 2rem;
`;

const LoginCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Logo = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-light);
`;

const Input = styled.input`
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid ${props => props.error ? 'var(--error)' : 'var(--border)'};
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 2.35rem;
  color: var(--text-light);
`;

const LoginButton = styled.button`
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #0d9488;
  }
  
  &:disabled {
    background-color: #e2e8f0;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: var(--error);
  background-color: rgba(239, 68, 68, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Message = styled.div`
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #fffbeb;
  border: 1px solid #fbbf24;
  border-radius: 4px;
  color: #92400e;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const LoginScreen = ({ onAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  // Check if already authenticated on component mount
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('mandjet_auth') === 'true';
    if (isAuthenticated) {
      onAuthenticated();
    }
  }, [onAuthenticated]);
  
  const handleLogin = (e) => {
    e.preventDefault();
    
    // Validate credentials (hardcoded for security demo)
    if (username === 'Alpine' && password === 'ClimateJustice') {
      // Store authentication in localStorage
      localStorage.setItem('mandjet_auth', 'true');
      onAuthenticated();
    } else {
      setError('Invalid username or password. Please try again.');
    }
  };
  
  return (
    <LoginContainer>
      <LoginCard>
        <Logo>Mandjet Planning</Logo>
        
        {error && (
          <ErrorMessage>
            <FiAlertTriangle />
            {error}
          </ErrorMessage>
        )}
        
        <Form onSubmit={handleLogin}>
          <InputGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={error}
              required
            />
            <IconWrapper>
              <FiUser />
            </IconWrapper>
          </InputGroup>
          
          <InputGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={error}
              required
            />
            <IconWrapper>
              <FiLock />
            </IconWrapper>
          </InputGroup>
          
          <LoginButton type="submit">
            Log In
          </LoginButton>
        </Form>
        
        <Message>
          Thank you for your interest in the Mandjet Planning demo. 
          Given the number of testers, please request a username and password 
          from <strong>christopherdumont04@gmail.com</strong> if you don't have one.
        </Message>
      </LoginCard>
    </LoginContainer>
  );
};

export default LoginScreen; 