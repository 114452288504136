import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import './App.css';
import styled from 'styled-components';
import { shouldShowApp } from './utils/environment';

// Components
import Sidebar from './components/layout/Sidebar';
import HeaderWithLogout from './components/layout/HeaderWithLogout';
import Footer from './components/layout/Footer';
import LoginScreen from './components/LoginScreen';

// Pages
import Dashboard from './pages/Dashboard';
import AssetList from './pages/AssetList';
import AssetDetail from './pages/AssetDetail';
import Reports from './pages/Reports';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import NotFound from './pages/NotFound';
import Simulations from './pages/Simulations';
import Notifications from './pages/Notifications';

// Theme
const theme = {
  colors: {
    primary: 'var(--primary)',
    secondary: 'var(--secondary)',
    background: 'var(--background)',
    text: 'var(--text)',
  }
};

// Add styled component for warning announcement bar
const WarningBar = styled.div`
  background-color: #ffedd5;
  border-bottom: 1px solid #f97316;
  color: #9a3412;
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  
  .main-warning {
    margin-bottom: 0.25rem;
  }
  
  .sub-warning {
    font-size: 0.8rem;
    opacity: 0.9;
  }
  
  .copyright {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.25rem;
  }
`;

// Auth protected route wrapper
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('mandjet_auth') === 'true';
  const location = useLocation();
  
  // Check if we should show the welcome page instead (based on domain)
  if (!shouldShowApp()) {
    window.location.href = '/welcome-page';
    return null;
  }
  
  if (!isAuthenticated) {
    // Save the route they were trying to access
    sessionStorage.setItem('redirect_after_login', location.pathname);
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

/**
 * Main application component
 * 
 * Features:
 * - Responsive layout with sidebar and header
 * - Route management for all application pages
 * - Warning bar for demo information
 * - Notification system with:
 *   - Real-time notifications in the header
 *   - Dedicated notifications page
 *   - Different notification types (risk alerts, reports, analytics updates)
 *   - Read/unread status tracking
 */
function App() {
  // Track if we're on a page that should show header/sidebar
  const [isAppPage, setIsAppPage] = useState(true);
  
  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('mandjet_auth');
    window.location.href = '/login';
  };

  // This check is specifically for the welcome page redirect
  if (!shouldShowApp()) {
    window.location.href = '/welcome-page';
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={isAppPage ? 'app app-page' : 'app landing-page'}>
          <Toaster position="top-right" />
          <ToastContainer position="top-right" autoClose={5000} />
          
          {/* Warning bar appears before everything else */}
          <WarningBar>
            <div className="main-warning">
              This is a demo and all of the numbers are completely made up and this is not meant to be accurate. This is not at all the full application which includes many variable adjustments and other modeling.
            </div>
            <div className="sub-warning">
              Species-specific risk pages are not added here. Please scroll down on each page to view all content and features.
            </div>
            <div className="copyright">
              Mandjet LLC Copyright 2025
            </div>
          </WarningBar>
          
          <Routes>
            {/* Login route */}
            <Route path="/login" element={<LoginScreen onAuthenticated={() => {
              const redirectPath = sessionStorage.getItem('redirect_after_login') || '/';
              sessionStorage.removeItem('redirect_after_login');
              window.location.href = redirectPath;
            }} />} />
            
            {/* Protected routes */}
            <Route path="/" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Dashboard />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Dashboard />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            {/* Asset routes */}
            <Route path="/asset-list" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <AssetList />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/assets" element={<Navigate to="/asset-list" replace />} />
            
            <Route path="/asset/:id" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <AssetDetail />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/assets/:id" element={<Navigate to="/asset-list" replace />} />
            
            {/* Reports and Analytics */}
            <Route path="/reports" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Reports />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/analytics" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Analytics />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/settings" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Settings />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            <Route path="/simulations" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Simulations />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            {/* Notifications */}
            <Route path="/notifications" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <Notifications />
                  </div>
                </div>
              </ProtectedRoute>
            } />
            
            {/* Fallback route - still protected */}
            <Route path="*" element={
              <ProtectedRoute>
                <div className="main-content" style={{ paddingTop: "8.5rem" }}>
                  <HeaderWithLogout 
                    style={{ position: 'fixed', top: '80px', left: 0, right: 0, zIndex: 900 }} 
                    onLogout={handleLogout}
                  />
                  <Sidebar />
                  <div className="content-area">
                    <NotFound />
                  </div>
                </div>
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
