/**
 * Environment utilities for Mandjet app
 */

// Check if we're running in local development or production
export const isLocalDevelopment = () => {
  return (
    window.location.hostname === 'localhost' || 
    window.location.hostname === '127.0.0.1'
  );
};

// Check if we're on the demo domain
export const isDemoDomain = () => {
  return window.location.hostname === 'demo.mandjetplanning.com';
};

// Check if we're on the main domain
export const isMainDomain = () => {
  return window.location.hostname === 'mandjetplanning.com';
};

// Should we show the main app or redirect?
export const shouldShowApp = () => {
  // Always show app in local development
  if (isLocalDevelopment()) {
    return true;
  }
  
  // In production, only show app on demo domain
  return isDemoDomain();
};

// Get the redirect URL if needed
export const getRedirectUrl = () => {
  if (isLocalDevelopment()) {
    return null; // No redirect in development
  }
  
  if (isDemoDomain()) {
    return null; // No redirect on demo domain
  }
  
  // On main domain, redirect to welcome page
  return '/welcome-page/index.html';
}; 